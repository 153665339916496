/* Custom styles for fixed header and scrollable body */
.custom-scroll-table {
    display: block !important;
  }

  .custom-scroll-table thead {
    position: sticky !important;
    top: 0 !important;
    z-index: 1 !important; /* Ensure it stays above the scrolling body */
    background: white !important; /* Match table header background */
  }

  .custom-scroll-table tbody {
    display: block !important;
    max-height: 500px !important; /* Match the height you want */
    overflow-y: auto !important;
    overflow-x: auto !important;

  }

  .custom-scroll-table tr {
    display: table !important;
    width: 100% !important;
    table-layout: fixed !important; /* Keeps columns aligned */
  }

  .custom-scroll-table thead tr {
    display: table !important;
    width: 100% !important;
    table-layout: fixed !important; /* Ensures headers align properly */
  }