.rowParent {
    display: flex;
    width: 90%;
    margin: auto;
}

.attributeName {
    width: 30%;
    text-align: left;
}

.attributeValueItem {
    background: blue;
    color: white;
    text-align: center;
}

.coupon-dropdwon .dropdown-menu[style] {
    right: auto !important;
    left: -110px !important;
}


.shipment-orders .dropdown-menu[style] {
    right: auto !important;
    /* left: -243px !important; */
    top: 260px !important;   
    /* bottom: 20px !important; */
    transform: translate3d(-191px, -212px, 0px) !important;
}

.shipment-orders-local .dropdown-menu[style] {
 right: auto !important;

    /* top: 10px !important;    */
    top: 180px !important;   
    transform: translate3d(-76px, -134px, 0px) !important
}

.shipping-calc .dropdown-menu[style] {
    right: auto !important;

    /* top: 10px !important;    */
    top: 180px !important;   
    transform: translate3d(-76px, -134px, 0px) !important



}

.ship_charge .dropdown-menu[style] {
    right: auto !important;

    /* top: 10px !important;    */
    top: 180px !important;   
    transform: translate3d(-105px, -134px, 0px) !important



}

.order-management .dropdown-menu[style] {

    right: auto !important;

    /* top: 10px !important;    */
    top: 180px !important;   
    transform: translate3d(-225px, -134px, 0px) !important


}

.order-management-local .dropdown-menu[style] {

    right: auto !important;

    /* top: 10px !important;    */
    top: 180px !important;   
    transform: translate3d(-125px, -134px, 0px) !important
}