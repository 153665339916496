/* Chat.module.css */

.chatContainer {
    margin-top: 50px;
  }
  
  .chatMessages {
    height: 600px;
    overflow-y: scroll;
  }
  
  .chatInput {
    margin-top: 40px;
  }
  
  .chatMessage {
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    /* width: fit-content; */
  }
  .createdAt {
    font-size: 0.6rem;
    text-align: right;
    color: #F0F0F0;
  }
  .createdAtAdmin {
    font-size: 0.6rem;
    text-align: right;
    color: #000;
  }
  .title {
    text-align: left;
    color: #F0F0F0;
    font-weight: 700;
  }
  .titleAdmin {
    text-align: left;
    color: #000;
    font-weight: 700;
  }
  .user {
    background-color: #ffffff;
    text-align: right;
    color:black;
  }
  
  .other {
    background-color: #00b4d8;
    color:white;
    text-align: left;
  }
  